<template>
  <v-card flat>
    <v-col cols="12">
      <v-alert
        type="info"
        outlined
        dense
        class="mb-0"
      >
        {{ $t('storeWidgetInformation') }}
      </v-alert>
    </v-col>
    <v-form v-model="valid">
      <v-expansion-panels
        flat
        accordion
        v-model="openExpansionPanel"
      >
        <template v-for="group in fieldGroups">
          <v-expansion-panel :key="group">
            <v-expansion-panel-header class="text-capitalize text-h6">
              {{ groupName(group) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-for="field in fieldsInGroup(group)">
                <v-col
                  v-if="field.show"
                  :key="field.key"
                  :cols="field.cols ? field.cols : '12'"
                >
                  <UiField
                    :value="field"
                    in-col
                    @change="setFieldValue"
                  />
                </v-col>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-form>
  </v-card>
</template>

<script>
import UiField from "@/components/uiField"

export default {
  name: "StoreWidgetForm",
  components: {
    UiField
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valid: false,
      storeWidget: null,
      fieldsWithValues: [],
      openExpansionPanel: 0
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'organization',
          label: this.$t('organization'),
          editable: false,
          show: true,
          type: 'select',
          options: this.organizations,
          value: this.organization.uuid,
          disabled: true,
          required: true,
          rules: [this.rules.required],
          group: 'base-settings'
        },
        {
          key: 'label',
          label: this.$t('label'),
          editable: true,
          show: true,
          type: 'text',
          required: true,
          rules: [this.rules.required],
          value: null,
          group: 'base-settings'
        },
        {
          key: 'active',
          label: this.$t('active'),
          editable: false,
          show: false,
          type: 'switch',
          value: null,
          group: 'base-settings'
        },
        {
          key: 'channel',
          label: this.$t('channel'),
          editable: true,
          show: true,
          type: 'select',
          multiple: false,
          options: this.channels,
          required: true,
          rules: [this.rules.required],
          value: null,
          group: 'base-settings'
        },
        {
          key: 'locations',
          label: this.$t('locations'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          options: this.locations,
          value: null,
          group: 'base-settings'
        },
        {
          key: 'categories',
          label: this.$t('categories'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          options: this.categories,
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('limitByCategories'),
          group: 'base-settings'
        },
        {
          key: 'paymentMethodInstances',
          label: this.$t('paymentMethodInstances'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('instanceOptionsHint'),
          options: this.paymentMethodInstances,
          noDataText: this.$t('noPaymentMethodInstances'),
          // primary: 'checkout',
          customDisplay: {
            selection: { primary: 'title', secondary: [ 'status' ]},
            items: { titleText: 'title ', subTitleText: [ 'checkout', 'terminal', 'status'] }
          },
          group: 'base-settings'
        },
        {
          key: 'deliveryMethodInstances',
          label: this.$t('deliveryMethodInstances'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('instanceOptionsHint'),
          options: this.deliveryMethodInstances,
          noDataText: this.$t('noDeliveryMethodInstances'),
          customDisplay: {
            selection: { primary: 'title', secondary: [ 'enabled' ]},
            items: { titleText: 'title ', subTitleText: [ 'enabled' ] }
          },
          group: 'base-settings'
        },
        {
          key: 'darkTheme',
          label: this.$t('darkTheme'),
          editable: true,
          show: false,
          type: 'switch',
          value: null,
          group: 'base-settings'
        },
        {
          key: 'defaultLanguage',
          label: this.$t('defaultLanguage'),
          editable: true,
          show: true,
          type: 'select',
          options: this.languages,
          group: 'base-settings'
        },
        {
          key: 'showVariationPrices',
          label: this.$t('showVariationPrices'),
          editable: true,
          show: true,
          type: 'switch',
          value: true,
          group: 'advanced-settings',
        },
        {
          key: 'collapseAllVariationGroups',
          label: this.$t('collapseAllVariationGroups'),
          editable: true,
          show: true,
          type: 'switch',
          value: false,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('collapseAllVariationGroupsHint')
        },
        {
          key: 'backgroundColor',
          label: this.$t('backgroundColor'),
          editable: true,
          show: true,
          type: 'color',
          hideDetails: true,
          value: null,
          group: 'display-settings'
        },
        {
          key: 'primaryColor',
          label: this.$t('primaryColor'),
          editable: true,
          show: true,
          type: 'color',
          hideDetails: true,
          value: null,
          group: 'display-settings'
        },
        {
          key: 'accentColor',
          label: this.$t('accentColor'),
          editable: true,
          show: true,
          type: 'color',
          hideDetails: true,
          value: null,
          group: 'display-settings'
        },
        {
          key: 'skipLocationSelection',
          label: this.$t('skipLocationSelection'),
          editable: true,
          show: true,
          type: 'switch',
          value: false,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('skipLocationSelectionInformation')
        },
        {
          key: 'showLocationDateSelection',
          label: this.$t('showLocationDateSelection'),
          editable: true,
          show: true,
          type: 'switch',
          value: true,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('showLocationDateSelectionInformation')
        },
        {
          key: 'locationDateSelectionDays',
          label: this.$t('locationDateSelectionDays'),
          editable: true,
          show: true,
          type: 'number',
          value: 7,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('locationDateSelectionDaysInformation')
        },
        {
          key: 'locationDateSelectionSpecificDays',
          label: this.$t('locationDateSelectionSpecificDays'),
          editable: true,
          show: true,
          type: 'multiple-dates',
          multiple: true,
          value: null,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('locationDateSelectionSpecificDaysInformation'),
          noIcon: true
        },
        {
          key: 'showLocationTimeSlotSelection',
          label: this.$t('showLocationTimeSlotSelection'),
          editable: true,
          show: true,
          type: 'switch',
          value: true,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('showLocationTimeSlotSelectionInformation')
        },
        {
          key: 'selectLocationFirst',
          label: this.$t('selectLocationFirst'),
          editable: true,
          show: true,
          type: 'switch',
          value: true,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('selectLocationFirstHint')
        }, 
        {
          key: 'metaPixelCode',
          label: this.$t('metaPixelCode'),
          editable: true,
          show: true,
          type: 'text',
          value: null,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('trackingIdForMetaPixel')
        },
        {
          key: 'allowedLanguages',
          label: this.$t('allowedLanguages'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          group: 'base-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('allowedLanguagesHint'),
          options: this.languages,
          multiple: true,
        },
        {
          key: 'applePayEnabled',
          label: this.$t('applePayEnabled'),
          editable: true,
          show: true,
          type: 'switch',
          value: false,
          group: 'advanced-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('applePayEnabledHint')
        },
        {
          key: 'orderFulfillments',
          label: this.$t('orderFulfillments'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          value: null,
          group: 'base-settings',
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('orderFulfillmentsHint'),
          options: this.orderFulfillmentOptions
        }
      ]
    },
    paymentMethodInstances() {
      // We only want active payment method instances and those that are available for checkout
      const paymentMethodInstances = Array.isArray(this.$store.state.paymentMethodInstances) 
        ? this.$store.state.paymentMethodInstances.filter(instance => 
            instance && 
            (instance.status === true && 
            instance.checkout === true) || 
            this.value?.paymentMethodInstances?.includes(instance.uuid)
          ) 
        : []

      return this.$store.getters.paymentMethodInstancesFormatForUiFields(paymentMethodInstances)
    },
    deliveryMethodInstances() {
      return this.$store.getters.deliveryMethodInstancesFormatForUiFields()
    },
    channels() {
      return this.$store.getters.channelsFormatForUiFields()
    },
    locations() {
      return this.$store.getters.locationsFormatForUiFields()
    },
    languages() {
      return this.$store.getters.languagesFormatForUiFields([{name: 'English', key: 'en'}, {name: 'Íslenska', key: 'is'}, {name: 'Svenska', key: 'sv'}, {name: 'Dansk', key: 'da'}])
    },
    categories() {
      let categories = this.$store.state.categories
      categories = categories.filter(category => category && category.administrative !== true && category.active !== false)
      if(Array.isArray(categories) && categories.length > 0) {
        return this.$store.getters.categoriesFormatForUiFields(categories)
      }
      return []
    },
    organization() {
      return this.$store.state.organization
    },
    organizations() {
      const currentOrganization = this.organization
      if(currentOrganization) {
        return [{
          key: currentOrganization.uuid,
          label: currentOrganization.label
        }]
      }
      return []
    },
    orderFulfillmentOptions() {
      return this.$store.getters.orderFulfillmentOptionsFormatForUiFields().filter(option => option && option.key !== 'Delivery')
    },
    rules() {
      return this.$store.getters.rules
    },
    fieldGroups() {
      return this.fieldsWithValues.reduce((groups, field) => {
        if(typeof field !== 'undefined' && field !== null) {
          if(typeof field.group !== 'undefined' && field.group !== null && !groups.includes(field.group)) {
            groups.push(field.group)
          }
        }
        return groups
      }, [])
    }
  },
  methods: {
    groupName(group) {
      return group.replace('-', ' ')
    },
    fieldsInGroup(group) {
      return this.fieldsWithValues.filter(field => field && field.group === group)
    },
    setFieldValue({ field, value }) {
      this.storeWidget[field.key] = value

      if (field.key === 'skipLocationSelection') {
        this.fields.find(i => i.key === 'selectLocationFirst').show = !value
        this.fields.find(i => i.key === 'showLocationDateSelection').show = !value
        this.fields.find(i => i.key === 'locationDateSelectionDays').show = !value
        this.fields.find(i => i.key === 'showLocationTimeSlotSelection').show = !value
        this.fields.find(i => i.key === 'locationDateSelectionSpecificDays').show = !value
      }

      this.$emit('input', this.storeWidget)
    },
    setFieldValues() {
      const newFields = []
      for(const index in this.fields) {
        const field = this.fields[index]
        if(field && field.key) {
          if(typeof this.storeWidget[field.key] !== 'undefined') {
            field.value = this.storeWidget[field.key]
            this.fields[index].value = this.storeWidget[field.key]
            if(field.key === 'applePayEnabled') {
              if(typeof field.value === 'undefined' || field.value === null) {
                field.value = true
              }
              if(typeof this.fields[index].value === 'undefined' || this.fields[index].value === null) {
                this.fields[index].value = true
              }
            }

          }
        }
        newFields.push(field)
      }
      this.fieldsWithValues = newFields
    }
  },
  watch: {
    valid(value) {
      this.$emit('valid', value)
    },
  },
  mounted() {
    this.storeWidget = this.value

    if(typeof this.storeWidget !== 'undefined' && this.storeWidget !== null) {

      if (this.storeWidget.skipLocationSelection !== null && this.storeWidget.skipLocationSelection !== undefined) {
        this.fields.find(i => i.key === "skipLocationSelection").value = this.storeWidget.skipLocationSelection
        this.fields.find(i => i.key === 'selectLocationFirst').show = !this.storeWidget.skipLocationSelection
        this.fields.find(i => i.key === 'showLocationDateSelection').show = !this.storeWidget.skipLocationSelection
        this.fields.find(i => i.key === 'locationDateSelectionDays').show = !this.storeWidget.skipLocationSelection
        this.fields.find(i => i.key === 'showLocationTimeSlotSelection').show = !this.storeWidget.skipLocationSelection
        this.fields.find(i => i.key === 'locationDateSelectionSpecificDays').show = !this.storeWidget.skipLocationSelection
      }

      this.setFieldValues()
    }
  },
  destroyed() {
    this.storeWidget = null
  }
}
</script>

<style scoped>

</style>
